import React, { useContext } from 'react'
import { Link } from 'theme-ui'
import { store } from '../../context/store'
import { FaPhoneAlt } from 'react-icons/fa'

export default function Logo({ logoOveride, ...rest }) {
  const { globalState, dispatch } = useContext(store)

  const phoneNumber = globalState?.businessData.phone

  return (
    <Link
      href={`tel:${phoneNumber}`}
      target='_blank'
      rel='noreferrer'
      {...rest}
      sx={{ ...styles.container, ...rest.sx }}
    >
      <FaPhoneAlt /> {phoneNumber}
    </Link>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'inherit',
    fontSize: '0.65rem',
    svg: {
      width: '20px',
      height: '20px',
      marginRight: '2',
      color: 'primary',
    },
  },
}
