import './src/gatsby-plugin-theme-ui/fonts.css';
import React from 'react';
import Layout from './src/components/Layout';
import { ContextProvider, store } from './src/context/store';

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <ContextProvider>
      <Layout {...props}>{element}</Layout>
      <form
        name='contact'
        data-netlify='true'
        netlify-honeypot='bot-field'
        hidden
      >
        <input type='text' name='name' />
        <input type='email' name='email' />
        <input type='radio' name='source' />
        <textarea name='message'></textarea>
      </form>

      <form
        name='private-dining-form'
        data-netlify='true'
        netlify-honeypot='bot-field'
        hidden
      >
        <input type='text' name='name' />
        <input type='email' name='email' />
        <input type='radio' name='source' />
        <input name='name' type='text' />
        <input name='email' type='email' />
        <input name='phone' type='phone' />
        <input name='eventtype' type='text' />
        <input name='eventdate' type='date' />
        <input name='flexibledate' type='checkbox' />
        <input name='eventtime' type='time' />
        <input name='numberguests' type='number' />
        <input name='companyname' type='text' />
        <textarea name='message'></textarea>
        <input name='source' type='radio' />
        <textarea name='message'></textarea>
      </form>
    </ContextProvider>
  );
};
