import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link } from 'gatsby'
import shortid from 'shortid'
import { Box, NavLink } from 'theme-ui'
import slugify from 'slugify'
import { FaCaretDown } from 'react-icons/fa'
import { store } from '../../../context/store'

export default function DropdownNavItemV1({ navLinks, navIndex, isNavBar }) {
  const { globalState, dispatch } = useContext(store)
  // ===== state =====
  const [dropdownOpen, toggleDropdown] = useState(false)

  // =====  variables transformations  =====

  const tagName = `${slugify(navLinks.name, { lower: true })}${
    navIndex ? navIndex : 0
  }`

  const dropdownRef = useRef() // the ref is used so the event listener has access to the latest state
  dropdownRef.current = dropdownOpen

  useEffect(() => {
    const handleClick = (e) => {
      console.log('clicking on dropdown')
      const navItemDropdown = document.getElementById(`${tagName}Dropdown`)
      const navItemLabel = document.getElementById(`${tagName}`)
      const withinBoundaries = e.composedPath().includes(navItemDropdown)
      // check if click in the dropdown
      if (withinBoundaries) {
        // if the click is the label toggle the dropdown else do nothing
        // this is to prevent the dropdown closing if the navlink is clicked
        if (e.composedPath().includes(navItemLabel)) {
          toggleDropdown(!dropdownRef.current)
          dispatch({
            type: 'CLOSE_ALL_POP_UPS',
          })
        }
      } else {
        if (dropdownRef.current === true) {
          toggleDropdown(false) // if the click isn't in the nav dropdown close it
        }
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
      toggleDropdown(false)
    }
  }, [])

  const styles = isNavBar ? navBarStyles : navMenuStyles

  return (
    <Box id={`${tagName}Dropdown`} sx={styles.container}>
      <NavLink
        sx={styles.linkContainer}
        as='li'
        id={`${tagName}`}
        key={shortid.generate()}
      >
        <a
          sx={styles.link}
          href='javascript:void(0);'
          onClick={() => false}
          className='navItemLink'
        >
          {navLinks.name} <FaCaretDown />
        </a>
      </NavLink>
      {dropdownOpen && (
        <Box className='navItemDropdownMenu' sx={styles.dropdownMenu}>
          {navLinks?.dropdownLinks?.map((dropdownLink, index) => {
            const { name, link } = dropdownLink
            return (
              <NavLink as='li' key={name + index}>
                <Link to={link} sx={styles.dropdownLink}>
                  {name}
                </Link>
              </NavLink>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

const navBarStyles = {
  container: {},
  dropdownMenu: {
    backgroundColor: 'white',
    borderTop: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    position: 'absolute',
    width: '100%',
    left: '0rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    variant: 'buttons.textOnly',
    padding: '0.5rem',
    color: '#666666',
    textAlign: 'center',
    fontSize: ['0.9rem', '', '', '', '1rem'],
    fontWeight: 'normal',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    svg: {
      color: 'primary',
      height: '18px',
      width: '18px',
    },
    ':hover': {
      textDecoration: 'underline',
    },
  },
  dropdownLink: {
    variant: 'buttons.textOnly',
    padding: '0.5rem',
    color: '#666666',
    textAlign: 'center',
    fontSize: ['0.9rem', '', '', '', '1rem'],
    fontWeight: 'normal',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',

    ':hover': {
      textDecoration: 'underline',
    },
  },
}

const navMenuStyles = {
  container: {},
  dropdownMenu: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'grey',
    fontSize: ['1.5rem', '', '2rem'],
    fontWeight: 'normal',
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
    margin: ['0rem auto 0rem', '0rem auto 0rem', '0rem auto 0rem'],
    svg: {
      color: 'light',
      height: '25px',
      width: '25px',
    },
    a: {
      svg: {
        margin: '0rem',
      },
    },
    ':hover': {
      color: 'lightgrey',
    },
  },
  dropdownLink: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'grey',
    fontSize: '1.2rem',
    fontWeight: 'normal',
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
    margin: ['0rem auto 0rem', '0rem auto 0rem', '0rem auto 0rem'],
    a: {
      svg: {
        margin: '0rem',
      },
    },
    ':hover': {
      textDecoration: 'underline',
    },
  },
}
