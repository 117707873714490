const allDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

function getAllIndexes(arr, val) {
  var indexes = [],
    i
  for (i = 0; i < arr.length; i++) if (arr[i] === val) indexes.push(i)
  return indexes
}

// =====================================================================
// param example
// i.e const daysOpen = ['Monday','Tuesday','Wednesday','Friday','Saturday','Sunday']

export default (daysOpen) => {
  // =====================================
  // Step 1 - get all indexes of the days
  // =====================================
  const daysIndexes = daysOpen.map((day) => {
    return allDays.indexOf(day)
  }) // returns [0,1,2,3,5,6,7]

  // ===================================================
  // Step 2 - Get All The Start Points of Adjacent Days
  // ===================================================

  const startPoints = daysIndexes.map((dayIndex, index) => {
    if (index === 0) {
      return 'startpoint' // if its index then its a start point
    } else {
      const previousIndex = index - 1
      const previousValue = daysIndexes[previousIndex]
      if (dayIndex !== previousValue + 1) {
        // if the previous Value is only one behind its adjacent if not its a new start point
        return 'startpoint'
      }
    }
  }) // i.e return ["startpoint", undefined, undefined, "startpoint", undefined, undefined]

  // ===============================================
  // Step 4 - Get indexes of all the start points
  // ===============================================

  // takes an array and the value you are looking for
  const startPointIndexes = getAllIndexes(startPoints, 'startpoint') // [0,3]

  // ==================================================
  // Step 6 - Get Start and Endpoints of adjacent days
  // ==================================================

  const buildArrays = startPointIndexes.map((startpoint, index) => {
    const lastElement = index === startPointIndexes.length - 1
    if (lastElement) {
      return [daysOpen[startpoint], daysOpen[daysOpen.length - 1]]
    } else {
      return [daysOpen[startpoint], daysOpen[startPointIndexes[index + 1] - 1]]
    }
  }) // i.e return [[Monday,Wednesday],[Friday, Sunday]]

  // ===================================================
  // Step 7 - Create The Label from start and End Points
  // ===================================================

  const labelDays = buildArrays.map((parallelDays) => {
    if (parallelDays[0] === parallelDays[1] || parallelDays[0] === 'Sunday') {
      return `${parallelDays[0]}, `
    }
    return `${parallelDays[0]} - ${parallelDays[1]}, `
  }) // i.e returns ['Monday - Wednesday', 'Friday - Sunday']

  return labelDays
}
