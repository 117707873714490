import React from 'react'
import { IoIosBusiness, IoIosRestaurant } from 'react-icons/io'
import { IoNewspaperOutline } from 'react-icons/io5'
import { GiKnifeFork, GiPartyFlags } from 'react-icons/gi'
import { BiCalendarEvent, BiNews, BiMailSend } from 'react-icons/bi'
import { GrGallery, GrMapLocation } from 'react-icons/gr'
import { AiOutlineTeam } from 'react-icons/ai'
import { MdCardGiftcard } from 'react-icons/md'
import { FaCalendarAlt, FaPhoneAlt, FaFileContract } from 'react-icons/fa'

// * three exports,  navLinks, footerLinks, allLinks

export const navLinks = [
  // {
  //   name: 'About',
  //   link: '',
  //   icon: <IoIosBusiness />,
  //   dropdownLinks: [
  //     {
  //       name: 'About Locanda Vecchia',
  //       link: '/about',
  //       icon: <IoNewspaperOutline />,
  //     },
  //   ],
  // },
  {
    name: 'About Locanda Vecchia',
    link: '/about',
    icon: <IoNewspaperOutline />,
  },
  {
    name: 'Menu',
    link: '/menu',
    icon: <GiKnifeFork />,
  },
  {
    name: 'Private Events',
    link: '/private-events',
    icon: <BiCalendarEvent />,
  },
  {
    name: 'Events',
    link: '/events',
    icon: <GiPartyFlags />,
  },
  {
    name: 'Gallery',
    link: '/gallery',
    icon: <GrGallery />,
  },
  // {
  //   name: 'Gift Cards',
  //   link: '/gift-cards',
  //   // icon: <GrGallery />,
  // },
  // {
  //   name: 'Gift Cards',
  //   link: '/gift-cards',
  //   icon: <MdCardGiftcard />,
  // },
  {
    name: 'Contact',
    link: '/contact',
    icon: <FaPhoneAlt />,
  },
  // {
  //   name: 'Reservations',
  //   link: '/reservations',
  //   icon: <FaCalendarAlt />,
  // },
  // {
  //   name: 'Order Online',
  //   link: 'order-online',
  //   icon: <IoIosRestaurant />,
  // },

  // {
  //   name: 'More',
  //   link: '',
  //   icon: <IoIosBusiness />,
  //   dropdownLinks: [
  //     {
  //       name: 'Join our Team',
  //       link: '/join-our-team',
  //       icon: <IoNewspaperOutline />,
  //     },
  //     {
  //       name: 'Join our Mailing List',
  //       link: '/mailing-list',
  //       icon: <IoNewspaperOutline />,
  //     },

  //     {
  //       name: 'Privacy Policy',
  //       link: '/policies/privacy-policy',
  //       icon: <IoNewspaperOutline />,
  //     },
  //     {
  //       name: 'Terms of Use',
  //       link: '/policies/terms-of-use',
  //       icon: <IoNewspaperOutline />,
  //     },
  //   ],
  // },
]

export const footerLinks = [
  {
    name: 'About Locanda Vecchia',
    link: '/about',
    icon: <IoNewspaperOutline />,
  },
  {
    name: 'Menu',
    link: '/menu',
    icon: <GiKnifeFork />,
  },
  {
    name: 'Private Events',
    link: '/private-events',
    icon: <BiCalendarEvent />,
  },
  {
    name: 'Events',
    link: '/events',
    icon: <GiPartyFlags />,
  },
  {
    name: 'Gallery',
    link: '/gallery',
    icon: <GrGallery />,
  },
  // {
  //   name: 'Gift Cards',
  //   link: '/gift-cards',
  //   icon: <MdCardGiftcard />,
  // },
  {
    name: 'Contact',
    link: '/contact',
    icon: <FaPhoneAlt />,
  },
  // {
  //   name: 'Join our Team',
  //   link: '/join-our-team',
  //   icon: <IoNewspaperOutline />,
  // },
  // {
  //   name: 'Join our Mailing List',
  //   link: '/mailing-list',
  //   icon: <IoNewspaperOutline />,
  // },
  // {
  //   name: 'Team',
  //   link: '/team',
  //   icon: <AiOutlineTeam />,
  // },
  // {
  //   name: 'Press',
  //   link: '/press',
  //   icon: <BiNews />,
  // },
  // {
  //   name: 'Livanos Restaurants',
  //   link: '/livanos-restaurants',
  //   icon: <GrMapLocation />,
  // },

  // {
  //   name: 'Privacy Policy',
  //   link: '/policies/privacy-policy',
  //   icon: <IoNewspaperOutline />,
  // },
  // {
  //   name: 'Terms of Use',
  //   link: '/policies/terms-of-use',
  //   icon: <IoNewspaperOutline />,
  // },
  // {
  //   name: 'Reservations',
  //   link: '/reservations',
  //   icon: <FaCalendarAlt />,
  // },
  // {
  //   name: 'Order Online',
  //   link: '/order-online',
  //   icon: <IoIosRestaurant />,
  // },
]

export const allLinks = [
  {
    name: 'About Locanda Vecchia',
    link: '/about',
    icon: <IoNewspaperOutline />,
  },
  {
    name: 'Team',
    link: '/team',
    icon: <AiOutlineTeam />,
  },
  {
    name: 'Press',
    link: '/press',
    icon: <BiNews />,
  },
  {
    name: 'Livanos Restaurants',
    link: '/livanos-restaurants',
    icon: <GrMapLocation />,
  },
  {
    name: 'Menu',
    link: '/menu',
    icon: <GiKnifeFork />,
  },
  {
    name: 'Private Events',
    link: '/private-events',
    icon: <BiCalendarEvent />,
  },
  {
    name: 'Events',
    link: '/events',
    icon: <GiPartyFlags />,
  },
  {
    name: 'Gallery',
    link: '/gallery',
    icon: <GrGallery />,
  },
  {
    name: 'Careers',
    link: '/careers',
    icon: <MdCardGiftcard />,
  },
  {
    name: 'Gift Cards',
    link: '/gift-cards',
    icon: <MdCardGiftcard />,
  },
  {
    name: 'Contact',
    link: '/contact',
    icon: <FaPhoneAlt />,
  },
  // {
  //   name: 'Reservations',
  //   link: '/reservations',
  //   icon: <FaCalendarAlt />,
  // },
  {
    name: 'Order Online',
    link: '/order-online',
    icon: <IoIosRestaurant />,
  },
  {
    name: 'Terms Of Use',
    link: '/terms',
    icon: <FaFileContract />,
  },

  {
    name: 'Join Our Mail list',
    link: '/mailing-list',
    icon: <BiMailSend />,
  },
]
