// ? === 3rd party imports ===
import React, { useEffect, useContext } from 'react';
import { Box, Image } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';

// ? === local imports ===
import { ContextProvider, store } from '../context/store';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import { allLinks, navLinks, footerLinks } from './navLinks';
import LoaderSpinner from './UI/LoaderSpinner';
import SEO from './seo';

// ? === component ===
export default function Layout(props) {
  const { globalState, dispatch } = useContext(store);

  const data = useStaticQuery(graphql`
    query siteQuery {
      businessData {
        ...businessDataFields
      }
      poweredImages {
        ...poweredImagesData
      }

      configData {
        ...allConfigDataFields
      }
      allGeneral {
        edges {
          node {
            ...generalArticleData
          }
        }
      }
    }
  `);

  const { imageBaseUrl, imagePrefix } = data?.businessData?.avatar;

  const { desc, name, tags } = data?.businessData;

  const logo = imageBaseUrl + '/' + imagePrefix;

  useEffect(() => {
    if (globalState.loadingApp) {
      console.log(props);
      dispatch({
        type: 'SET_APP_DATA',
        configData: data.configData,
        businessData: data.businessData,
        stories: data.allGeneral.edges,
        poweredImages: data.poweredImages,
      });
      dispatch({
        type: 'FINISH_APP_LOADING',
        loadingApp: false,
      });
    }
    return () => {};
  }, [globalState.loadingApp]);

  const renderApp = () => {
    if (globalState.loadingApp) {
      return (
        <Box sx={styles.spinner}>
          <LoaderSpinner />
          <Image src={logo} sx={styles.logo} alt='Locanda Vecchia...' />
        </Box>
      );
    } else {
      return (
        <>
          {' '}
          <SEO
            description={desc}
            title={name}
            keywords={tags}
            favicon={imageBaseUrl + '/' + imagePrefix}
          />
          <Header
            location={props.location}
            navLinks={navLinks}
            allLinks={allLinks}
          />
          {props.children}
          <Footer links={footerLinks} />
        </>
      );
    }
  };

  return <Box sx={styles.container}>{renderApp()}</Box>;
}

// ? === styles ===

const styles = {
  container: {},

  spinner: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    // filter: 'brightness(0)',
    maxHeight: '120px',
    paddingLeft: '1.5rem',
  },
};
