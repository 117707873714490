import React, { useContext } from 'react'
import { Button, Box, Link } from 'theme-ui'
// import { store } from '../../context/store'
// import { AiOutlineClose } from 'react-icons/ai'

export default function OrderOnline(props) {
  // const { globalState, dispatch } = useContext(store)

  // const popUpOpenStyles = globalState.appState.onlineOrderWidget
  //   ? { ...styles.popUpOpen }
  //   : {}

  // const toggleWidget = () => {
  //   dispatch({
  //     type: 'TOGGLE_ONLINE_ORDERING_WIDGET',
  //   })
  // }

  return (
    <>
      <Link
        {...props}
        sx={{ ...styles.container, ...props.sx }}
        // onClick={toggleWidget}
        target='_blank'
        href={
          'https://www.opentable.com/restaurant/takeout/1066330?lang=en-US&widget=true&corrid=391c0a6e-9b2a-4f05-9542-a702d2de57dc'
        }
      >
        Order Online
      </Link>

      {/* <Box sx={{ ...styles.popUp, ...popUpOpenStyles }}>
        <Box sx={styles.background} onClick={toggleWidget} />
        <AiOutlineClose sx={styles.closeBtn} onClick={toggleWidget} />
        <iframe src='https://www.opentable.com/restaurant/takeout/1066330?lang=en-US&widget=true&corrid=391c0a6e-9b2a-4f05-9542-a702d2de57dc'></iframe>
      </Box> */}
    </>
  )
}

const styles = {
  container: {
    variant: 'buttons.primary',
    padding: '0.5rem',
    display: 'flex',
    margin: '0rem 0rem 0rem 0.5rem',
    fontSize: '0.75rem',
  },
  background: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    height: '100vh',
    position: 'absolute',
    zIndex: '-1',
    top: '0rem',
    left: '0rem',
  },
  closeBtn: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    width: '40px',
    height: '40px',
    color: 'white',
    backgroundColor: 'primary',
    borderRadius: '100px',
    padding: '0.25rem',
    transition: 'all ease-in-out 0.3s',
    ':hover': {
      filter: 'brightness(0.8)',
    },
  },

  popUp: {
    position: 'fixed',
    top: '-200vh',
    left: '0%',
    transition: 'all ease-in-out 1s',
    opacity: '0',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    iframe: {
      width: '85%',
      height: ['85vh', '', '80vh'],
      borderRadius: '10px',
      '-ms-overflow-style': 'none' /* IE and Edge */,
      'scrollbar-width': 'none' /* Firefox */,
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  popUpOpen: {
    top: '0',
    opacity: '1',
  },
}
