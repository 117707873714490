import React, { useState, useContext } from 'react'
import { Box, Text, Heading } from 'theme-ui'
import HoursDisplay from './HoursDisplay'
import { FaClock } from 'react-icons/fa'
import { store } from '../../context/store'
import { AiOutlineClose } from 'react-icons/ai'

export default function HoursPopUp({ sx }) {
  const { globalState, dispatch } = useContext(store)
  const { businessData } = globalState

  const [displayHours, setDisplayHours] = useState(false)

  const activePopStyles = displayHours ? styles.activePopStyles : {}

  console.log(activePopStyles)
  return (
    <Box sx={{ ...styles.container, ...sx }}>
      <Box sx={styles.label} onClick={() => setDisplayHours(!displayHours)}>
        <FaClock />
        <Text>Our Hours</Text>
      </Box>
      <Box className='popup' sx={{ ...styles.popup, ...activePopStyles }}>
        <AiOutlineClose
          sx={styles.closeBtn}
          onClick={() => setDisplayHours(false)}
        />
        <HoursDisplay hours={businessData.hours} />
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: '5000',
    margin: '0 0rem 0rem auto',
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'grey',
    letterSpacing: '1px',
    cursor: 'pointer',
    transition: 'all ease-in-out 0.5s',
    span: {
      display: ['none', 'inline'],
    },
    svg: {
      fontSize: '1rem',
      marginRight: 2,
      color: 'primary',
    },
    ':hover': {
      textDecoration: 'underline',
    },
  },
  popup: {
    position: 'fixed',
    left: '-150vw',
    bottom: '42px',
    zIndex: '5000',
    backgroundColor: '#f9f9f9',
    padding: '1rem',
    transition: 'all ease-in-out 0.8s',
    maxHeight: 'calc(100vh - 102px)',
    width: '100%',
    maxWidth: '400px',
    border: 'solid lightgrey 1px',
    boxShadow: '0 0 3px 0 rgb(0 0 0 / 30%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '.innerHoursContainer ': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '.heading': {
      color: 'primary',
      textTransform: 'uppercase',
      margin: '0rem 0rem 0rem 1rem',
      width: '100%',
    },
    '.typeContainer': {
      margin: '1rem',
      order: '2',
      flexGrow: '1',
    },
    '.brunch ': {
      order: '1',
    },

    '.typelabel': {
      //   textDecoration: 'underline',
      paddingBottom: '0.5rem',
      marginTop: '0.5rem',
      textTransform: 'uppercase',
      borderBottom: 'solid 1px',
      borderColor: 'primary',
      color: 'primary',
    },
    '.day': {
      fontSize: '0.8rem',
    },
    '.time ': {
      fontSize: '0.8rem',
    },
  },
  activePopStyles: {
    left: '0rem',
    bottom: '42px',
  },
  closeBtn: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    width: '40px',
    height: '40px',
    padding: '0.5rem',
    backgroundColor: 'primary',
    color: 'white',
    borderRadius: '500px',
    opacity: '0.7',
  },
}
