import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from 'theme-ui';
import * as Yup from 'yup';
import FormikControl from './FormComponents/FormikControl';
import netlifyFormEncodeBody from '../helpers/netlifyFormEncodeBody';

function ContactForm(props) {
  const { sx, style } = props;

  const sourceOptions = [
    { key: 'Google', value: 'google' },
    { key: 'Yelp', value: 'yelp' },
    { key: 'Trip Advisor', value: 'trip-advisor' },
    { key: 'Media', value: 'media' },
    { key: 'Recommendation', value: 'recommendation' },
    { key: 'Other', value: 'other' },
  ];
  const initialValues = {
    name: '',
    phone: '',
    email: '',
    message: '',
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email format').required('Required'),
    phone: Yup.string()
      .required('Required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    message: Yup.string().required('Required'),
  });

  const onSubmit = (values, { resetForm }) => {
    console.log('Form data', values);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: netlifyFormEncodeBody({
        'form-name': 'contact',
        ...values,
      }),
    })
      .then(() => {
        alert('Email Sent!');
        resetForm({});
      })
      .catch((error) => alert(error));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form sx={{ ...styles.container, ...style, ...props.sx }}>
            <input type='hidden' name='form-name' value='contact' />
            <FormikControl
              control='input'
              type='name'
              label='Name'
              name='name'
              placeholder='Enter Your Name'
            />
            <FormikControl
              control='input'
              type='email'
              label='Email'
              name='email'
              placeholder='Enter Your Email Address'
            />

            <FormikControl
              control='input'
              type='phone'
              label='Phone'
              name='phone'
              placeholder='Enter Your Phone Number'
            />
            <FormikControl
              placeholder='Enter Your Message Here'
              control='textarea'
              label='Message'
              name='message'
              rows='5'
            />

            <FormikControl
              control='radio'
              label='How Did You Hear About Us?'
              name='source'
              options={sourceOptions}
            />
            <Button
              sx={styles.submitBtn}
              type='submit'
              disabled={!formik.isValid}
            >
              Submit
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

const styles = {
  container: {
    '.form-control': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: '1rem',
    },
    '.radio-control': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'wrap',
      '.radioButtonContainer': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '0rem 1rem 0.5rem 0rem',
      },
      input: {
        width: 'auto',
        margin: '0rem 0.5rem 0rem 0rem',
      },
    },

    '.input-control': {
      input: {
        border: 'none',
        borderBottom: 'solid 1px lightgrey',
        width: '100%',
        padding: '0.75rem',
        '::placeholder': {
          fontSize: '0.7rem',
          opacity: '0.7',
        },
      },
    },

    '.form-label': {
      width: '100%',
    },

    '.error': {
      color: 'red',
      fontSize: '0.75rem',
      textAlign: 'right',
      width: '100%',
    },

    textarea: {
      border: 'none',
      borderBottom: 'solid 1px lightgrey',
      margin: '0.5rem 0rem',
      width: '100%',
      padding: '0.75rem',

      resize: 'none',
      '::placeholder': {
        fontSize: '0.7rem',
        opacity: '0.7',
      },
    },

    submitBtn: {
      variant: 'buttons.primary',
    },
  },
};

export default ContactForm;

// import React, { useContext } from 'react';
// import { Textarea, Input, Button, Box } from 'theme-ui';
// import { store } from '../context/store';

// export default function ContactForm(props) {
//   const { globalState, dispatch } = useContext(store);
//   const { sx, style } = props;
//   const formName = 'contact';

//   return (
//     <Box
//       sx={{ ...styles.container, ...style, ...props.sx }}
//       as='form'
//       name={'contact'}
//       method='POST'
//       netlify={'true'}
//       data-netlify='true'
//       data-netlify-honeypot='bot-field'
//     >
//       <Input type='hidden' name='form-name' value={'contact'} />
//       <Input type='hidden' name='bot-field' />
//       <Input
//         sx={styles.input}
//         name='name'
//         type='text'
//         id='name'
//         placeholder='Name'
//         className='inputField name'
//         required
//       />
//       <Input
//         sx={styles.input}
//         type='phone'
//         name='phone'
//         id='phone'
//         placeholder='Phone'
//         className='inputField email'
//       />
//       <Input
//         sx={styles.input}
//         type='email'
//         name='email'
//         id='email'
//         placeholder='Email'
//         className='inputField email'
//         required
//       />

//       <Textarea
//         sx={styles.textArea}
//         name='message'
//         id='message'
//         rows='4'
//         placeholder='Message'
//         className='textField message'
//         required
//       />
//       <Button className='button submitBtn' type='submit'>
//         Submit
//       </Button>
//     </Box>
//   );
// }

// const styles = {
//   container: {},
//   input: {
//     border: 'none',
//     borderBottom: 'solid 1px lightgrey',
//     margin: '0.5rem 0rem',
//     '::placeholder': {
//       fontSize: '0.7rem',
//     },
//   },
//   textArea: {
//     border: 'none',
//     borderBottom: 'solid 1px lightgrey',
//     margin: '0.5rem 0rem',
//     resize: 'none',
//     '::placeholder': {
//       fontSize: '0.7rem',
//     },
//   },
// };

// // ContactForm.defaultProps = {
// //   formName: 'contact-form',
// //   sx: {},
// // }
