export default (state, action) => {
  switch (action.type) {
    // ============================
    // ==== inital app Loading ====
    // ============================

    case 'SET_APP_DATA':
      const { configData, businessData, stories, poweredImages } = action

      return {
        ...state,
        configData,
        businessData,
        stories,
        poweredImages,
      }

    case 'FINISH_APP_LOADING':
      return {
        ...state,
        loadingApp: false,
      }

    case 'TOGGLE_RESERVATIONS_WIDGET':
      return {
        ...state,
        appState: {
          ...state.appState,
          reservationsPopUp: !state.appState.reservationsPopUp,
          onlineOrderWidget: false,
          contactFormPopUp: false,
        },
      }
    case 'TOGGLE_ONLINE_ORDERING_WIDGET':
      return {
        ...state,
        appState: {
          ...state.appState,
          onlineOrderWidget: !state.appState.onlineOrderWidget,
          reservationsPopUp: false,
          contactFormPopUp: false,
        },
      }
    case 'TOGGLE_CONTACT_FORM_POPUP':
      return {
        ...state,
        appState: {
          ...state.appState,
          contactFormPopUp: !state.appState.contactFormPopUp,
          onlineOrderWidget: false,
          reservationsPopUp: false,
        },
      }

    case 'CLOSE_ALL_POP_UPS':
      return {
        ...state,
        appState: {
          ...state.appState,
          contactFormPopUp: false,
          onlineOrderWidget: false,
          reservationsPopUp: false,
        },
      }

    default:
      alert('Error - No Action Could Be Found')
      throw new Error()
  }
}
