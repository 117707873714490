const customStyles = {
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconText: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      marginRight: '0.5rem',
    },
  },
}

export default customStyles
