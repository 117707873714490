import React, { useContext } from 'react'

import { Link } from 'gatsby'
import { Box, Heading, Image, Text, Link as ExternalLink } from 'theme-ui'

import { store } from '../../context/store'
import SocialIcons from '../UI/SocialIcons'
import Address from '../UI/Address'
import Phone from '../UI/Phone'
export default function TopFooter() {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <Heading sx={styles.heading} as='h3'>
          Get In Touch
        </Heading>
        <Box sx={styles.contactDetails}>
          <Phone sx={styles.phone} />
          <Box sx={styles.seperator} />
          <Address sx={styles.address} />
        </Box>
        <SocialIcons />
      </Box>
      <Box sx={styles.map}>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3015.0208494364974!2d-74.38080448447532!3d40.9152887330905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3066c67ec20d9%3A0x2eb1a84cb6e3643b!2sLocanda%20Vecchia!5e0!3m2!1sen!2sus!4v1635360426574!5m2!1sen!2sus'
          allowFullScreen=''
          loading='lazy'
        ></iframe>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    justifyContent: 'center',
    alignItems: 'stretch',
    position: 'relative',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: ['100%', '', '50%'],
    padding: ['1rem', '', '2rem'],
    backgroundColor: 'primary',
  },
  heading: {
    color: 'light',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    marginBottom: '1.5rem',
    fontSize: '2.5rem',
  },
  map: {
    width: ['100%', '', '50%'],
    iframe: {
      height: '100%',
      width: '100%',
      minHeight: '400px',
      border: 'none',
    },
  },
  contactDetails: {
    display: 'flex',
    width: '100%',
  },
  phone: {
    color: 'light',
    fontSize: '1rem',
    padding: '1rem',
    width: '50%',
    svg: {
      color: 'light',
    },
    transition: 'all ease-in-out 0.6s',
    ':hover': {
      opacity: '0.6',
    },
  },
  seperator: {
    borderRight: 'solid 1px white',
  },
  address: {
    color: 'light',
    padding: '1rem',
    fontSize: '1rem',
    width: '50%',
    svg: {
      color: 'light',
    },
    transition: 'all ease-in-out 0.6s',
    ':hover': {
      opacity: '0.6',
    },
  },
}
